import { navigate } from '@gatsbyjs/reach-router';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import useRole from '../../hooks/useRole';
import SearchComponent from './SearchComponent';

const SearchPage: React.FC<{}> = () => {
  const { isSearcher } = useRole();

  useEffect(() => {
    if (!isSearcher) {
      navigate('/documents');
    }
  }, []);

  return (
    <Box height="calc(100vh - 48px)" sx={{ overflowY: 'auto' }}>
      <SearchComponent />
    </Box>
  );
};

export default SearchPage;
