// @ts-nocheck
import React from 'react';

import SearchPage from '../components/SearchPage/SearchPage';
import AppLayout from '../components/layout/AppLayout';

const Document = () => {
  return (
    <AppLayout>
      <SearchPage path="/search" />
    </AppLayout>
  );
};
export default Document;
